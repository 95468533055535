import React, {useEffect, useState, useMemo} from 'react'
import Publish from '../Index_n_publish/Publish'
import { Link } from 'react-router-dom'
import Articles from '../Articles/Articles'
import  Volume from '../data_json/data.json' 




function Issues() {


  return (

    <div className="container">
    <div className="row">
        <div className="col-sm-8 mb-3 mb-sm-0">

            <div className="card border-0">
            <div className="card-body">
                <h2 className="card-title">Volumes and Issues</h2>

                <div className="card border-0 w-100 mb-3 my-4">
                <div className="card-body rounded-3" style={{background:"rgb(255,243,243)"}}>

                    {Volume.Volume && Volume.Volume.map((e,index)=>(

                        <div className="container" key={index}>
                        <p className="gap-1" >
                        <a className="btn btn-primary" data-bs-toggle="collapse" href={`#collapseExample${index}`} role="button" aria-expanded="false" aria-controls={`collapseExample${index}`}>
                            Volume {e.volume}
                        </a>
                        </p>
                        <div className="collapse" id={`collapseExample${index}`}>
                        <div className="card card-body">

                            {e.Issues && e.Issues.map((w,ind)=>(
                                <div className="card-text" key={ind}>
                                
                                    <h5 className="d-inline-flex gap-1">
                                        <a className="icon-link icon-link-hover" style={{'--bs-link-hover-color-rgb': '25, 135, 84'}} data-bs-toggle="collapse" href={`#collapseExample-${ind}-${index}`} role="button" aria-expanded="false" aria-controls={`collapseExample-${ind}-${index}`}>
                                            <h5 className="card-title"> Issue {w.issue} | {w.date}</h5>
                                            
                                        </a>
                                    </h5>
                                        <div className="collapse" id={`collapseExample-${ind}-${index}`}>
                                        <div className="card card-body">

                                            {w.paper.reverse().map((q,inde)=>(

                                            
                                            <div className="container" key={inde}>
                                                <hr className="bg-danger border-2 border-top border-dark" />
                                                    <h4><Link to={`/articles/${e.volume}/${w.issue}/${q.id}`} state={q} className="link-body-emphasis link-offset-2 link-underline-opacity-25 link-underline-opacity-75-hover">{q.title}</Link></h4>
                                                    <p className="card-title">{q.author[0]} & {q.author[1]}</p>
                                                    <p className="card-title">Original Research | Date | pages: {q.pages} </p>
                                                <hr className="bg-danger border-2 border-top border-dark" />
                                            </div>

                                            ))}
                                        </div>
                                        </div>
                            </div>


                            ))}

                            
                            </div>
                        </div>
                        </div>
                      
                    ))}                       
                </div>
                </div>
            </div>
            </div>



        </div>

        <div className="col-sm-4">
            <Publish />
           
        </div>
    </div>

    </div>
   
  )
}

export default Issues