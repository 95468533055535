import React from 'react'
import Publish from '../Index_n_publish/Publish'
import Data from '../data_json/data.json';
import { Link } from 'react-router-dom';

function Allarticle() {
  return (
        <div className="container">
    
        <div className="row">
            <div className="col-sm-8 mb-3 mb-sm-0">
                <div className="card my-2 border-0">
                <div className="card-body">
                  <h1 className="card-title">Articles</h1>

                  {Data.Volume && Data.Volume.map((e,index)=>(

                        <div class="card my-3">
                        <div class="card-body">
                          
                          {e.Issues && e.Issues.map((w, ind)=>(

                            <div class="card my-2" style={{"width": "48rem"}}>
                            <div class="card-body">
                              {w.paper && w.paper.map((q,indk)=>(

                                <>
                                  <h4><Link to={`/articles/${e.volume}/${w.issue}/${q.id}`} state={q} className="link-body-emphasis link-offset-2 link-underline-opacity-25 link-underline-opacity-75-hover">{q.title}</Link></h4>
                                  <p class="card-text">pages: {q.pages} | {q.author[0]} & {q.author[1]}
                                  
                                  </p>

                                  <hr className="bg-danger border-2 border-top border-dark" />
                                  
                                  
                                
                                </>

                              ))}
                            </div>
                          </div>

                          

                          ))}
                        </div>
                        </div>

                  ))}

    
                </div>
                </div>
            </div>
    
            <div className="col-sm-4">
                <Publish />
            </div>
        </div>
    
        </div>
  )
}

export default Allarticle