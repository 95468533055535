import React from 'react'
import Publish from '../Index_n_publish/Publish'

function Editors() {

  const Directors=[
    // {
    //   name: "Prof. Sukumar Nandi, CSED, Indian Institute of Technology, Guwahati, India.",
    //   position:"Director of Journal"
    // },
    {
      name: " S. K. Pal, Senior Scientist, DRDO, Delhi, India",
      position:"Director of Journal"
    },
    {
      name:" Karan Singh, Assistant Professor, SC&SS, JNU, New Delhi, India",
      position:"Editor in Chief"
    },
    {
      name:"G. R. Bhengra , Scientist, C-DAC,  Mumbai, India.",
      position:"Co-Editor in Chief"
    },
    {
      name:" P. K. Saxena, Scientist ISRO, Ahemdabad, India.",
      position:"Managing Editor"
    },

    {
      name:" S Sanjib Kumar, Assistant Professor, KIET Group of Institution, Ghaziabad",
      position:"Managing Editor"
    }
  ]

  const associate =[
    " Winfried E. Kuehnhause, Professor & Head, Department of Computer Science, Technical University of Ilmenau, Germany.",
    " Cheng-Chi Lee, Distinguished Professor, Department of Library and Information Science, Fu Jen Catholic University, Taiwan.",
    // " Ziyad Al-Khinalie, Multimedia University (MMU) in Selangor, Malaysia.",
    " Anish Mathuria, Professor at DA-IICT, India.",
    " Indranil Sen Gupta, Professor Computer Science & Engineering Indian Institute Of Technology Kharghpur, India.",
    " Kumkum Garg, Professor Department of Electronics & Computer Science Indian Institute Of Technology, Roorkee, India.",
    " Amitabha Das, School of Computer Engineering Nanyang Technological University, Singapore.",
    "Satya P. Singh, Nanyang Technological University, Singapore ",
    " Rekha Singhal, Senior Researcher, Tata Consultancy Services Limited.",
    "Walid Osamy, Benha University, Egypt",
    "Anura P. Jayasumana, Colorado State University, Fort Collins, Colorado, USA",
    " M. P. Sebastian, Professor & Head, Department of Computer Science & Engineering, National Institute Of Technology, Calicut India.",
    " Meenu Chawla, Professor & Head, Professor at Maulana Azad National Institute of Technology, Bhopal,India.",
    " Ajay Kumar Sharma, Director, National Institute Of Technology, Delhi, India.",
    "Ali Ahmadian, Universiti Putra Malaysia, Selangor Malaysia",
    " Virendra Ranga, Professor Department of Computer Science & Engineering National Institute Of Technology, Kurukshetra, India.",
    "Ahmed Mohamed Aziz Ismail, Benha University, Egypt",
    " V. Ch. Venkaiah, Professor, School of Computer and Information Sciences, University of Hyderabad,, India.",
    // "Md. Akbar Hossain, Auckland University of Technology, Auckland, New Zealand",
    "Mohamed Abdel-Basset, Zagazig University, Egypt",
    " S.S.Sridhar, Professor & Head, Department of Computer Science & Engineering, SRM University, Kattankulathur, Tamilnadu, India.",
    "Mehdi Salimi, Simon Fraser University, Canada",
    " Aditya Bagchi, Emeritus Professor at Ramakrishna Mission Vivekananda University, West Bengal, India.",
    " Subhamay Maitra, Senior Scientist, Indian Statistical Institute, Calcutta, India.",
    // " Ashok Deobhakta, Senior Director, IIQM, Information Security Management, Ministry of Information Technology, India.",
    " V. R. Ghorpade, Professor/Principal at Bharati Vidyapeeth College of Engineering Kolhapur, India",
    " Sanjeev Kumar, Indian Institute of Technology Roorkee Roorkee, Uttarakhand, India ",
    "Soheil Salahshour, Bahcesehir University, Turkey",
    " Yicong Zhou, Department of Computer and Information Science University of Macau Macau, China",
    "Norazak Senu, Institute for Mathematical Research, Universiti Putra Malaysia, Selangor, Malaysia"
    // "Er. S. A. Sayad, Senior, Scientist Center for Development of Advanced Computing, Mumbai, India.",
    // "Er. Mahdi Sharique, Forensic & Dispute Services Deloitte Financial Advisory Service LLP, USA",
    // "Er. Arati Paul, Scientist, Indian Space Research Organization, RRSSC-Kharagpur, India.",
    // "Er N. K. Sharma, Scientist, Bhart Electronics Limited, Noida, India.",
    // "Er. S. K. Gupta, Engineer, Software Company Sam sung, Noida, India.",
    // "Er. Shailendra Kumar, Scientist, Defense Research and Development Organization, Dehradun,",
    // "Er. Raman Kumar, Department of Computer Science Engineering, National Institute Of Technology, Jalandhar, India."

  ]
  return (
    <div className="container">

    <div className="row">
        <div className="col-sm-8 mb-3 mb-sm-0">
            <div className="card border-0">
            <div className="card-body">
              <h2 className="card-title">Editorial Board Honorable Position::</h2>
              <hr className="bg-danger border-2 border-top border-dark" />

                <ul className="container my-3">
                  
                  {Directors.map((e,index)=>(
                    <li className='my-5'>
                      <h3 className="card-title">{e.name}</h3>
                      <h4 className="card-title text-body-secondary">{e.position}</h4>
                      <hr className="bg-danger border-2 border-top border-dark" />
                    </li>
                    
                  ))}

                </ul>

                <h2 className="card-title">Associate Editors:</h2>

                <ul className="container my-3" style={{textAlign:"justify"}}>
                  {associate.map((e,index)=>(
                    <li className="my-2">{e}</li>
                  ))}
                </ul>

                <p className="card-text" style={{textAlign:"justify"}}>Thrilled to announce my appointment to the editorial board of Internation Journal of Secure Digital Information Age. Excited to contribute to shaping the future of research in the mentaioned tracks. Looking forward to collaborating with esteemed colleagues and guiding impactful publications. Grateful for this opportunity to advance scholarship and promote academic excellence. Let's embark on this journey together towards innovation and knowledge dissemination.  </p>

                <a href="#" className="btn btn-primary">Join Us</a>
            </div>
            </div>
        </div>

        <div className="col-sm-4">
            <Publish />
        </div>
    </div>

    </div>
  )
}

export default Editors